.reviews__partners_logos {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  img {
    width: 25%;
    place-self: center;
    margin: 1em;
  }
}

ul.reviews__items {
  display: flex;
  justify-content: space-between;
  padding: 2em 0;
  gap: 1em;
  overflow-x: scroll;
}

/* Works on Firefox */
ul.reviews__items {
  scrollbar-width: thin;
  scrollbar-color: $clr-3 $clr-3-sec;
}

/* Works on Chrome, Edge, and Safari */
ul.reviews__items::-webkit-scrollbar {
  width: 12rem;
}

ul.reviews__items::-webkit-scrollbar-track {
  background: $clr-3-sec;
}

ul.reviews__items::-webkit-scrollbar-thumb {
  background-color: $clr-3;
  border-radius: 20rem;
  border: 3rem solid $clr-3-sec;
}

.reviews__item {
  padding: 3em 2em;
  min-width: 280rem;
  border-radius: 2rem;
  background: $clr-white;
  font-size: $p-base;
  box-shadow: 0 2rem 4rem 0rem $clr-black-sec;
}

.reviews__item__message {
  margin: 0 0 2em 0;
  line-height: 1.5em;
}

.reviews__item__message::first-letter {
  font-size: 2em;
  color: $clr-2;
  font-weight: 900;
}

.reviews__item footer {
  color: $clr-1;
  font-weight: 700;
  text-align: center;
}

@media (min-width: 768px) {
  ul.reviews__items {
    margin: 80rem 0 0 0;
  }

  .reviews__partners_logos {
    padding: 0 0 80rem 0;
    border-bottom: 1rem solid $clr-2;

    img {
      width: 20%;
    }
  }
}

@media (min-width: 1025px) {
  .reviews__partners_logos {

    img {
      width: 10%;
      margin: 0;
    }
  }
}

@media (min-width: 1300px) {}