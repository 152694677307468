.special_banner__wrapper {
  position: relative;
  height: 100px;
  font-size: $p-xlarge;
  letter-spacing: 1rem;
  color: $clr-white;
  background: $clr-gradient-main;
  transition: .3s ease-out;
}

.special_banner__wrapper>.container {
  height: 100%;
}

.special_banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.special_banner__button--close {
  margin-left: 40rem;
  font-weight: 900;
  font-size: $p-xlarge;
}

.special_banner--closed {
  height: 0;
}

.special_banner--closed .special_banner {
  opacity: 0;
}