html {
  font-size: 1px;
}

body {
  font-family: $font-base;
  font-size: $p-base;
  font-weight: 400;
  line-height: 1.3;
  color: $clr-font-base;
}