.hero_wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  background:
    url('../../../images/hero/hero_mobile.png'), linear-gradient(0deg, #FDFFFC, #ffc97f);
  background-position: bottom;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hero__content {
  display: grid;
  margin: 0 auto;
  width: 90%;
  text-align: right;
  row-gap: 12rem;
}

// .hero__content__title--main {
//   font-size: map-get($title-xlarge, "portrait");
//   font-family: $font-title;
//   font-weight: 900;
//   line-height: .7em;
// }

.hero__content__title--main {
  font-size: map-get($title-xlarge, "portrait");
  font-family: $font-title;
  text-transform: uppercase;
  font-weight: 900;
  line-height: .8em;
}

.hero__bang {
  color: $clr-1;
}

.hero__content__title--secondary {
  margin: 0 0 0 20%;
  font-size: map-get($title-small, "portrait");
}

.hero__form {
  display: flex;
  justify-content: flex-end;
  height: 40rem;

  input {
    margin: 0;
    padding-left: 16rem;
    transition: ease-in-out .4s;
    border: none;
    border-radius: 4rem;
    outline: none;
  }

  input[type="email"] {
    width: 50%;
  }

  input[type="email"]:focus {
    width: 100%;
  }

  button.btn--submit,
  input[type="submit"] {
    min-width: 100rem;
    background: $clr-1;
    color: $clr-white;
    height: 100%;
  }
}

.hero__adnotation {

  .btn {
    text-decoration: underline;
  }
}

@media(orientation: landscape) {
  .hero__content__title--main {
    font-size: map-get($title-xlarge, "landscape");
  }

  .hero__content__title--secondary {
    margin: 0;
    font-size: map-get($title-xsmall, "landscape");
  }

  .hero__content {
    margin: 0 10%;
    width: 80%;
    text-align: center;
    row-gap: 16rem;
  }

  .hero__form {
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .hero_wrapper {
    background: url('../../../images/hero/hero_tablet.png'), linear-gradient(0deg, #FDFFFC 0 50%, #ffc97f 51% 99%);
    background-size: 101% auto;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .hero__content {
    margin: 0 10% 20%;
    width: 80%;
    text-align: center;
    row-gap: 16rem;
  }

  .hero__content__title--main {
    font-size: map-get($title-large, "portrait");
  }

  .hero__content__title--secondary {
    margin: 0;
    font-size: map-get($title-xxsmall, "portrait");
  }

  .hero__form {
    justify-content: center;

    input[type="email"]:focus {
      width: 70%;
    }
  }

  .hero__adnotation {
    text-align: center;

    &:hover .btn {
      color: $clr-1;
    }
  }

  @media(orientation: landscape) {
    .hero_wrapper {
      background: url('../../../images/hero/hero_mobile.png'), linear-gradient(0deg, #FDFFFC, #ffc97f);
    }

    .hero__content {
      margin: 0 10%;
    }

    .hero__content__title--main {
      font-size: map-get($title-xlarge, "landscape");
    }

    .hero__content__title--secondary {
      font-size: map-get($title-xsmall, "landscape");
    }
  }
}


@media (min-width: 1024px) {

  .hero__content {
    margin: 10% 5% 0 50%;
    margin: 0 auto;
    width: 700px;
    row-gap: 21rem;
    text-align: center;
  }

  .hero__content__title--main {
    font-size: map-get($title-medium, "portrait");
  }

  .hero__content__title--secondary {
    font-size: map-get($title-xxsmall, "portrait");
  }

  .hero__form {
    input {
      display: inline-block;
      margin: 0;
      padding: 12px;
      height: 40px;
      border: none;
      transition: .4s ease-in-out;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
    }

    input[type="email"] {
      width: 50%;
    }

    input[type="submit"] {
      min-width: 100px;
      background: $clr-3;
      color: $clr-white;
      font-size: $p-base;
      letter-spacing: 1px;
    }

    &:focus-within input[type="submit"] {
      background: $clr-2;
    }

    input[type="submit"]:hover {
      background: $clr-1;
      font-weight: 600;
      letter-spacing: 0px;
    }
  }

  @media(orientation: landscape) {
    .hero_wrapper {
      background: url('../../../images/hero/hero4.png'), linear-gradient(90deg, #FDFFFC 0 50%, #ffc97f 51% 99%);
      background-size: auto 101%;
      background-position: center;
      background-repeat: no-repeat;
    }

    .hero__content {
      margin: 10% 5% 0 40%;
      text-align: right;
      justify-items: end;
    }

    .hero__form {
      // justify-content: flex-end;
      display: block;
      width: 80%;
    }

    .hero__adnotation {
      text-align: right;
    }

    .hero__content__title--main {
      font-size: map-get($title-medium, "landscape");
    }

    .hero__content__title--secondary {
      font-size: map-get($title-xxsmall, "landscape");
    }
  }
}

@media (min-width: 1200px) {
  .hero__content {
    // margin: 5% 5% 0 40%;
  }

  .hero__content__title--main {
    font-size: map-get($title-xlarge, "portrait");
  }

  @media(orientation: landscape) {
    .hero__content__title--main {
      font-size: map-get($title-medium, "landscape");
    }

    .hero__content__title--secondary {
      font-size: map-get($title-xxsmall, "landscape");
    }
  }
}

@media (min-width: 1800px) {
  @media (orientation: landscape) {
    .hero__content {
      margin: 10% 5% 0 45%;
    }

    .hero__content__title--main {
      font-size: 120px;
    }

    .hero__content__title--secondary {
      font-size: 30px;
    }
  }
}