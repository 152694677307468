@import './reset';
@import './normalize';
@import './variables';
@import './fonts';
@import './typography';
@import './layers';
@import './brakepoints';

*::-moz-selection {
  background: $clr-2;
  color: $clr-white;
}

*::selection {
  background: $clr-2;
  color: $clr-white;
}