.btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn_green {
  color: $clr-2;
  background-color: #FFFFFF;
}

.btn_red {
  color: $clr-2;
  background-color: #FFFFFF;
}

.cookies_btn {
  width: clamp(200px, 200px, 90%);
  padding: 8px 16px;
  border: 1px solid $clr-2;
  border-radius: 4px;

  &:hover {
    transform: scale(1.01, 1.02);
  }
}