.contact {
  background: $clr-gradient-sec;
}

.form_wrapper {
  position: relative;
  height: 550rem;
  bottom: -40rem;
  border-radius: 6rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0rem 1rem 1rem, rgba(0, 0, 0, 0.07) 0rem 2rem 2rem, rgba(0, 0, 0, 0.07) 0rem 4rem 4rem, rgba(0, 0, 0, 0.07) 0rem 8rem 8rem, rgba(0, 0, 0, 0.07) 0rem 16rem 16rem;
}

.form_wrapper::before {
  content: "";
  background: $clr-gradient-main;
  position: absolute;
  top: -6rem;
  left: -6rem;
  width: calc(100% + 12rem);
  height: calc(100% + 12rem);
  z-index: 1;
  border-radius: 12rem;
}

.form_wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $clr-white;
  border-radius: 8rem;
  z-index: 1;
}

.flip_wrapper {
  position: relative;
  height: 100%;
  z-index: 2;
}

.react-card-flip {
  height: 100%;
}

.contact_form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 10%;

  fieldset,
  input,
  textarea {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    border: none;
    resize: none;
    background: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0rem 100rem $clr-1-sec inset;
    -webkit-box-shadow: 0 0 0rem 100rem $clr-1-sec inset;
  }

  input,
  textarea {
    margin: 1em 0;
    padding: 8rem 16rem;
    border-bottom: 1rem solid $clr-black-sec;
    outline-color: $clr-2;
  }

  .btn--submit {
    display: block;
    padding: 12rem 0;
    margin: 0 auto;
    min-width: 200rem;
    color: $clr-white;
    font-size: $p-base;
    font-weight: 500;
    background: $clr-1;
    border-radius: 4rem;
  }
}

.contact_form__header {
  font-size: $p-large;
  color: $clr-font-base;
  font-weight: 500;
  text-align: center;
  z-index: 2;
}

.thank_you_note {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  text-align: center;
  font-family: $font-fancy;
  font-size: $p-large;
}

.thank_you_note__header {
  font-size: $p-xlarge;
}

@media (min-width: 768px) {

  .contact_form {

    textarea {
      height: 6em;
    }
  }
}

@media (min-width: 1200px) {

  .contact_img {
    position: absolute;
    height: 80%;
    top: 20%;
    right: 10%;
    right: 5%;
    z-index: 2;
  }

  .flip_wrapper {
    position: absolute;
    top: 0;
    left: 0%;
    right: 50%;
  }

  .contact_form {
    .btn--submit {
      opacity: .9;
    }

    .btn--submit:hover {
      opacity: 1;
    }
  }
}

@media (min-width: 1300px) {}