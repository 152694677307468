.about__items {
  // margin: 0 5%;
}

.krakow_logo {
  width: auto;
  margin: 48rem auto 0;
}

.about__items article {
  padding: 3em 2em;
  border-radius: 2px;
}

.about__items article:nth-child(1) {
  border-top: 6px solid $clr-0;
}

.about__items article:nth-child(2) {
  border-top: 6px solid $clr-1;
}

.about__items article:nth-child(3) {
  border-top: 6px solid $clr-2;
}

.about__items article:nth-child(4) {
  border-top: 6px solid $clr-3;
}

.about__item__title {
  margin: 0 0 12px 0;
  font-size: $p-large;
  font-weight: 900;
  letter-spacing: 1px;
}

.about__item__text {
  text-align: justify;
  line-height: 1.5em;
}

.about__slogan {
  // margin: 3em 1em 0;
  margin-top: 3em;
  text-align: center;
  font-family: $font-title-sec;
  font-size: $p-xlarge;
}

@media (min-width: 768px) {

  .about__items {
    // margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr) minmax(200px, 1fr));
    gap: 20px;
  }

  .about__slogan {
    font-size: $p-xxlarge;
  }
}

@media (min-width: 1200px) {
  .about__item {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1300px) {}