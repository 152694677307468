.free_lesson {
  padding: 200rem 0;
  background: linear-gradient(180deg, transparent, $clr-1-sec 30%);
  font-family: $font-fancy;
}

.free_lesson__title {
  font-size: map-get($title-small, "portrait");
  text-align: center;
}

.free_lesson__subtitle {
  font-size: map-get($title-xsmall, "portrait");
  text-align: center;
}

.free_lesson__content {
  margin: 1em 0 2em 0;
  text-align: center;
  font-size: $p-base;

  strong {
    color: $clr-1;
  }
}

.free_lesson__form {
  display: flex;
  justify-content: center;
  font-family: $font-base;
  -webkit-filter: drop-shadow(0 0 8rem #00000011);
  filter: drop-shadow(0 0 8rem #00000011);
  transition: .4s ease-in-out;

  input {
    border: none;
    transition: .4s ease-in-out;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
  }

  input::-webkit-input-placeholder {
    color: $clr-black-sec;
  }

  input:-ms-input-placeholder {
    color: $clr-black-sec;
  }

  input::-ms-input-placeholder {
    color: $clr-black-sec;
  }

  input::placeholder {
    color: $clr-black-sec;
  }

  input[type="email"] {
    width: 60%;
    padding: 8rem 16rem;
  }

  input[type="submit"] {
    min-width: 100rem;
    background: $clr-1;
    color: $clr-white;
    font-size: $p-base;
    letter-spacing: 1rem;
  }
}

.free_lesson__form:focus-within {
  -webkit-filter: drop-shadow(0 0 1rem $clr-black-sec);
  filter: drop-shadow(0 0 1rem $clr-black-sec);

  input[type="email"] {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .free_lesson {
    background: $clr-1-sec;
  }

  .free_lesson__content {
    font-size: $p-large;
  }

  .free_lesson__title {
    font-size: map-get($title-xxsmall, "portrait");
  }

  .free_lesson__subtitle {
    font-size: $p-xxlarge;
    text-align: center;
  }

  .free_lesson__form {
    input[type="email"] {
      width: 40%;
    }

    input[type="submit"]:hover {
      background: $clr-1;
      font-weight: 600;
      letter-spacing: 0;
    }
  }

  .free_lesson__form:focus-within {
    -webkit-filter: drop-shadow(0 0 1rem $clr-black-shadow);
    filter: drop-shadow(0 0 1rem $clr-black-shadow);

    input[type="email"] {
      flex-grow: unset;
      width: 50%;
    }
  }

  @media(orientation: landscape) {
    .free_lesson__title {
      font-size: map-get($title-xsmall, "landscape");
    }
  }
}

@media (min-width: 1200px) {
  @media(orientation: landscape) {
    .free_lesson__title {
      font-size: map-get($title-xsmall, "landscape");
    }
  }
}