@-webkit-keyframes marker {
  0% {
    width: 0;
  }

  100% {
    width: 105%;
  }
}

@keyframes marker {
  0% {
    width: 0;
  }

  100% {
    width: 105%;
  }
}

@-webkit-keyframes bang {
  to {
    -webkit-transform: skewX(-3deg);
    transform: skewX(-3deg);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
}

@keyframes bang {
  to {
    -webkit-transform: skewX(-3deg);
    transform: skewX(-3deg);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;

  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;

  }
}

@-webkit-keyframes disappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.bang {
  position: relative;
  display: inline-block;
  -webkit-animation: bang 1s ease-in-out forwards;
  animation: bang 1s ease-in-out forwards;
  text-transform: uppercase;
}

.bang::before {
  content: "";
  position: absolute;
  top: -2rem;
  left: -6rem;
  height: 108%;
  padding: 0 12rem;
  background: $clr-3-alt;
  z-index: -1;
  -webkit-animation: marker .6s ease-in-out forwards;
  animation: marker .6s ease-in-out forwards;
}

.back_to_top {
  position: fixed;
  bottom: 0%;
  right: 0%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0 0 10px;
  height: 50rem;
  width: 50rem;
  background: $clr-1-alt;
  border-radius: 100% 0 0 0;
  text-align: center;
  font-size: 27rem;
  color: $clr-1-alt;
  color: white;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: 4;

  &:-webkit-any-link {
    color: $clr-1;
  }

  &:any-link {
    color: $clr-white;
  }
}

.appear {
  -webkit-animation: appear .4s ease-in forwards;
  animation: appear .4s ease-in forwards;
}

.disappear {
  -webkit-animation: disappear .4s ease-out forwards;
  animation: disappear .4s ease-out forwards;
}

@media(min-width: 678px) {
  .back_to_top {
    bottom: 5%;
    right: 5%;
    padding: 0;
    background: $clr-white-sec;
    border: 3px solid $clr-1-alt;
    border-radius: 50%;
    color: $clr-1-alt;

    &:hover {
      transition: .3s;
      color: $clr-1;
      border-color: $clr-1;
    }

    &::after {
      content: "To Top";
      display: none;
      position: absolute;
      bottom: -35rem;
      left: -20rem;
      right: -20rem;
      text-align: center;
      font-size: $p-large;
      -webkit-animation: appear .3s ease-in forwards;
      animation: appear .3s ease-in forwards;
    }

    &:hover::after {
      display: block;
      color: $clr-1-alt;
    }

    &:any-link {
      color: $clr-1-alt;
    }
  }

  .navbar {
    align-items: center;
  }

  .navbar__logo_wrapper {
    display: flex;
    align-items: center;
  }
}