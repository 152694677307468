// COLORS
$clr-background: #fdfffc;
$clr-black: #010100;
$clr-black-sec: #01010033;
$clr-black-shadow: #01010011;
$clr-white: #f3f3f3;
$clr-white-sec: #f3f3f333;
$clr-white-alt: #f3f3f3DD;
$clr-0: #9e07ae;
$clr-0-sec: #9e07ae10;
$clr-1: #e5202f;
$clr-1-sec: #e5202f10;
$clr-1-alt: #e5202f90;
$clr-2: #f06424;
$clr-2-sec: #f0642410;
$clr-3: #ff9f1c;
$clr-3-sec: #ff9f1c10;
$clr-3-alt: #ff9f1c90;
$clr-font-base: #404040;
$clr-font-sec: #707070;
$clr-gradient-main: linear-gradient(162deg, $clr-0 0%, $clr-1 33%, $clr-2 66%, $clr-3 100%);
$clr-gradient-sec: linear-gradient(180deg, transparent, $clr-1-sec 30%);

// COMPONENTS DIMENTIONS
$container-large: 1200rem;
$container-medium: 940rem;
$container-small: 900rem;
$navbar_h--desktop: 100rem;
$navbar_h--mobile: 80rem;
$header_h: 680rem;

// FONTS
// family
$font-base: BlinkMacSystemFont,
'Segoe UI',
'Roboto',
'Oxygen',
'Ubuntu',
'Cantarell',
'Fira Sans',
'Droid Sans',
'Helvetica Neue',
sans-serif;

$font-title: 'Jaldi',
sans-serif;

// $font-title: 'Heebo',
// sans-serif;

$font-title-sec: 'Montserrat',
sans-serif;

$font-title-alt: 'PT Serif',
serif;

$font-fancy: 'PT Serif',
serif;

// sizes
// $title-xlarge: 120rem;
// $title-large: 85rem;
// $title-medium: 56rem;
// $title-small: 36rem;
// $title-xsmall: 24rem;
$p-xxlarge: 40rem;
$p-xlarge: 21rem;
$p-large: 18rem;
$p-base: 15rem;
$p-small: 12rem;

$title-xlarge: ("portrait": 16vw, "landscape": 16vh);
$title-large: ("portrait": 12vw, "landscape": 12vh);
$title-medium: ("portrait": 10vw, "landscape": 10vh);
$title-small: ("portrait": 6vw, "landscape": 6vh);
$title-xsmall: ("portrait": 5vw, "landscape": 5vh);
$title-xxsmall: ("portrait": 3vw, "landscape": 3vh);

// @media (orientation: portrait) {
// }

// @media (orientation: landscape) {
//   $title-xlarge: 16vh !global;
//   $title-medium: 12vh !global;
//   $title-large: 10vh !global;
//   $title-small: 6vh !global;
//   $title-xsmall: 3vh !global;
// }