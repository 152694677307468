footer.footer {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  background: $clr-font-base;
}

.footer__main,
.footer__slogan,
.footer__nav {
  margin-top: 1em;
  margin: 1em auto;
}

footer * {
  color: $clr-white;
  font-weight: 100;
}

.footer__slogan {
  padding: 1em 0;
  text-align: center;
  font-weight: 300;
  letter-spacing: 4px;
  border-bottom: 1px solid dimgray;
}

.footer__main {

  .footer__socials {
    display: flex;
    justify-content: space-evenly;
  }

  .footer__socials a {
    font-size: $p-base;
  }

  .footer__logo {
    width: 60%;
    margin: 10% auto;
  }

  .footer__address {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-style: normal;
    font-size: $p-base;
  }
}

.footer__nav {
  .footer__nav__links {
    display: flex;
    justify-content: space-evenly;
    margin: 1em 0;
  }

  .btn--btt {
    display: block;
    padding: 1em 0;
    text-align: center;
    border-bottom: 1px solid dimgray;
  }
}

@media(min-width: 768px) {
  .footer__main {
    display: flex;
    justify-content: space-between;
    height: 160px;

    .footer__socials {
      flex-direction: column;
      justify-content: space-evenly;
      align-items: baseline;

      a {
        font-size: $p-large;
        min-width: 120rem;
      }
    }

    .footer__logo {
      max-width: 30%;
      margin: 0;
    }

    .footer__address {
      min-width: 250rem;
    }
  }

}

@media (min-width: 1200px) {

  .footer__main,
  .footer__slogan,
  .footer__nav {
    margin: 5% auto 0;
    max-width: $container-medium;
  }

  .footer__main .footer__socials a {
    font-size: $p-xlarge;
  }

  .footer__logo {
    max-width: 50%;
  }
}