.navbar_wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  position: fixed;
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: 10;
  transition: .25s ease-in-out;
}

.navbar {
  display: flex;
  margin: 1% 0;
}

#sidebarMenu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 250ms ease-in-out;
  background: $clr-white;
}

ul.sidebarMenuInner {
  margin: 20% 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 60%;
  width: 100%;
}

input[type="checkbox"]:checked~#sidebarMenu {
  transform: translateX(0);
}

input[type=checkbox] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}

.hamburger1 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  top: 19px;
  right: 5%;
  height: 22px;
  width: 22px;

  .spinner {
    background: $clr-1;
  }
}

.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff;
}

.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}

.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}

.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}

input[type=checkbox]:checked~.hamburger1>.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}

input[type=checkbox]:checked~.hamburger1>.diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}

input[type=checkbox]:checked~.hamburger1>.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}

.navbar__logo_wrapper {
  width: 100rem;
  height: 60rem;
  display: flex;
  align-items: center;
}

.navbar__logo {
  width: 100%;
}


ul.navbar__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
  list-style: none;
}

.navbar__links__element {
  font-size: $p-xlarge;
  font-weight: 500;
}

.icon_field {
  font-weight: 300;
  display: flex;
  position: relative;
}

.navbar__socials {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 5%;
  left: 10%;
  font-size: 30rem;
  color: $clr-1;

  a {
    margin-right: 12rem;
  }
}

@media(min-width: 768px) {
  .navbar {
    height: $navbar_h--desktop;
  }

  .hamburger1 {
    top: 48rem;
  }

  .navbar__logo_wrapper {
    width: 160rem;
  }

  @media(orientation: "landscape") {
    .navbar__logo_wrapper {
      width: 100rem;
    }
  }
}

@media (min-width: 1024px) {
  .navbar_wrapper {
    // width: $container;
    // margin-left: auto;
    // margin-right: auto;
  }

  .blur {
    //   background: $clr-white-sec;
    backdrop-filter: blur(10px);
  }

  .navbar {
    height: $navbar_h--desktop;
  }

  .openSidebarMenu,
  .hamburger1 {
    display: none;
  }

  #sidebarMenu {
    position: static;
    width: 100%;
    margin-top: 0;
    transform: translateX(0);
    background: none;
  }

  ul.sidebarMenuInner {
    margin: 0;
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .hamburger,
  .hamburger_is_active {
    display: none;
  }

  .navbar__logo_wrapper {
    width: 200rem;
  }

  ul.navbar__links {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  .navbar__socials {
    display: none;
  }

  .navbar__links__element {
    position: relative;

    &:hover {
      color: $clr-1;
    }
  }

  .hover_animation::after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 1em;
    width: 100%;
    border-bottom: 2px solid;
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }

  .hover_animation:hover::after {
    opacity: 1;
    -webkit-transform: scale(0.6, 1);
    transform: scale(0.6, 1);
  }
}

@media(min-width: 1200px) {
  .navbar__logo_wrapper {
    width: 260rem;
  }
}

@media (max-height: 500px) {
  ul.sidebarMenuInner {
    height: 100%;
    margin: 0;
  }
}