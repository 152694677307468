.offer__item:nth-child(1) {
  background: $clr-0-sec;
  border-left: 6rem solid $clr-0;
}

.offer__item:nth-child(2) {
  background: $clr-1-sec;
  border-left: 6rem solid $clr-1;
  background-position: right;
}

.offer__item:nth-child(3) {
  background: $clr-2-sec;
  border-left: 6rem solid $clr-2;
  background-position: right;
}

.offer__item:nth-child(4) {
  background: $clr-3-sec;
  border-left: 6rem solid $clr-3;
}

.offer__item__text {
  // padding: 5% 5% 0;
  padding: 5%;
}

.offer__item__text__title {
  margin: 0 0 1em 0;
  font-size: map-get($title-small, "portrait");
  text-align: left;
  color: #333;
  font-weight: 500;
}

.offer__item__text__description {
  // margin: .5em 0;
  font-size: $p-large;
  text-align: left;
  line-height: 1.4em;
  letter-spacing: 1rem;
}

.order-4 {
  order: 4;
}

.offer__item__img img {
  margin: 0 auto;
  padding-bottom: 5%;
  width: 50%;
}

@media(orientation: landscape) {
  .offer__item__text__title {
    font-size: map-get($title-medium, "landscape");
  }

  .offer__item__text__description {
    font-size: $p-xlarge;
  }

  .offer__item__img img {
    width: 30%;
  }
}

@media (min-width: 768px) {
  .offer__item {
    display: flex;
    min-height: 300rem;
    margin: 24rem 0;
    border-radius: 12rem;
    box-shadow: 0rem 3rem 3rem $clr-black-shadow;
  }

  .offer__item__img {
    display: flex;
    align-items: center;
  }

  .offer__item__text {
    display: flex;
    justify-content: center;
    width: 50%;
  }

  .offer__item__text {
    flex-direction: column;
  }

  .offer__item__img img {
    width: 60%;
  }

  .offer__item__text__title {
    font-size: map-get($title-small, "portrait");
  }

  .offer__item__text__description {
    font-size: $p-large;
  }

  @media(orientation: landscape) {
    .offer__item__text__title {
      font-size: map-get($title-xxsmall, "landscape");
    }

    .offer__item__text__description {
      font-size: $p-large;
    }
  }
}

@media(min-width: 1024px) {
  @media(orientation: landscape) {
    .offer__item__text__title {
      font-size: map-get($title-xsmall, "landscape");
    }

    .offer__item__text__description {
      font-size: $p-large;
    }
  }
}

@media (min-width: 1200px) {
  @media(orientation: landscape) {
    .offer__item__text__title {
      font-size: map-get($title-xxsmall, "landscape");
    }

    .offer__item__text__description {
      font-size: $p-large;
    }
  }
}