body {
  background: $clr-background;
}

.container {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}

.anti_container {
  margin: 0;
  width: 100%;
}

main>section {
  margin-top: 80rem;
  // display: flex;
  // justify-content: center;
}

.section__title {
  margin: 1em 10%;
  font-family: $font-title;
  color: $clr-font-base;
  text-align: center;
  font-size: map-get($title-medium, "portrait");
}

.section__secondary_title {
  font-family: $font-title-alt;
  line-height: 1.5em;
  font-weight: 500;
  font-size: map-get($title-xsmall, "portrait");
}

@media(orientation: landscape) {
  .container {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .section__title {
    font-size: map-get($title-large, "landscape");
  }

  .section__secondary_title {
    font-size: map-get($title-xsmall, "landscape");
  }
}

@media (min-width: 768px) {
  .anti_container {
    margin-left: 5%;
    margin-right: 5%;
  }

  .section__title {
    margin: 80rem 0;
    font-weight: 600;
    text-align: left;
    font-size: map-get($title-small, "portrait");
  }

  .section__secondary_title {
    font-size: map-get($title-xxsmall, "portrait");
  }

  @media(orientation: landscape) {
    .section__title {
      font-size: map-get($title-large, "landscape");
    }

    .section__secondary_title {
      font-size: map-get($title-xsmall, "landscape");
    }
  }
}

@media (min-width: 1024px) {

  .container,
  .anti_container {
    margin: 0 auto;
    width: $container-small;
  }

  @media(orientation: landscape) {
    .section__title {
      font-size: map-get($title-small, "landscape");
    }

    .section__secondary_title {
      font-size: map-get($title-xxsmall, "landscape");
    }
  }
}

@media (min-width: 1200px) {

  .container,
  .anti_container {
    margin: 0 auto;
    width: $container-medium;
  }
}