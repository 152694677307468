@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cookies {
  min-height: 300px;
  padding: 2vh 1vw;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  background: #ffffffee;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid gray;
  opacity: 0;
  animation: appear 500ms;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.cookies__booton_group {
  width: clamp(40%, 70%, 90%);
  display: flex;
  justify-content: space-evenly;
}

.cookies__content {
  width: clamp(40%, 70%, 90%);
  margin: 5vh;

  p {
    line-height: 1.5em;
  }
}

.cookies__content--more {
  overflow: auto;
  max-height: 85vh;
  padding-top: 5%;

  ul {
    list-style: inside;

    p {
      margin-left: 20px;
      margin-top: 8px;
    }
  }
}

@media (min-width: 768px) {
  .cookies__content--more {
    padding-top: 0;
  }
}